import React, { Component } from "react"
import Slider from "react-slick"
import PropTypes from "prop-types"

import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"

class OrderSlider extends Component {
    componentDidMount() {
        document.querySelectorAll(".slick-active").forEach(x => {
            x.classList.add("order-slider__dots-active")
        })
    }

    render() {
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            className: "slide-wrap",
            dotsClass: "order-slider__dots",
            afterChange: () => {
                document.querySelectorAll(".slick-active").forEach(x => {
                    x.classList.remove("order-slider__dots-active")
                    x.classList.add("order-slider__dots-active")
                })
            },
        }

        let { orderSteps } = this.props.data.options

        if (!orderSteps.length) {
            return <div />
        }

        return (
            <div className="order-slider">
                <div className="overlay overlay--ink" />
                <div className="site-width">
                    <h4 className="order-slider__title">How to Order</h4>
                    <Slider {...settings}>
                        {orderSteps.map((step, i) => (
                            <div className="slide" key={i}>
                                <h3
                                    className="slide__title"
                                    dangerouslySetInnerHTML={{
                                        __html: step.stepTitle,
                                    }}
                                />
                                <div
                                    className="slide__body"
                                    dangerouslySetInnerHTML={{
                                        __html: step.stepContent,
                                    }}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        )
    }
}

OrderSlider.propTypes = {
    orderSteps: PropTypes.arrayOf(
        PropTypes.shape({
            stepContent: PropTypes.string,
            stepTitle: PropTypes.string,
        })
    ).isRequired,
}

OrderSlider.defaultProps = {
    orderSteps: [],
}

export default OrderSlider
