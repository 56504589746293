import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import FoodItems from "../components/FoodItems"
import OrderBar from "../components/OrderBar"
import OrderSlider from "../components/OrderSlider"
import Hero from "../components/Hero"
import SEO from "../components/seo"
import FixedOrderBtn from "../components/FixedOrderBtn"
import Navbar from "../components/Navbar"

const IndexPage = data => (
    <Layout>
        <SEO
            title="Yujo"
            description={data.data.wordpressAcfOptions.options.site_description}
            image={data.data.wordpressAcfOptions.options.seo_image.source_url}
        />
        <Navbar pageID={data.data.wordpressPage.wordpress_id} />
        <FixedOrderBtn data={data.data.wordpressAcfOptions.options} />
        <Hero data={data} pageClassName="home" />
        <OrderBar data={data.data.wordpressAcfOptions.options} />
        <FoodItems />
        <OrderSlider data={data.data.wordpressAcfOptions} />
    </Layout>
)

export default IndexPage

export const pageQuery = graphql`
    query {
        wordpressPage(slug: { eq: "home" }) {
            content
            wordpress_id
            title
            featured_media {
                source_url
                title
                localFile {
                    childImageSharp {
                        fixed(width: 500) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            acf {
                heroContent
            }
        }
        wordpressAcfOptions {
            options {
                toast
                deliveryService {
                    serviceName
                    serviceUrl
                }
                orderSteps {
                    stepTitle
                    stepContent
                }
                site_description
                seo_image {
                    source_url
                }
            }
        }
    }
`
