import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const FoodItems = () => (
    <StaticQuery
        query={graphql`
            query {
                allWordpressWpFood {
                    nodes {
                        title
                        id
                        slug
                        content
                        acf {
                            summary
                            showReadMore
                        }
                        featured_media {
                            source_url
                            title
                            localFile {
                                childImageSharp {
                                    fixed(width: 500) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={data => (
            <div className="menu-item-wrap">
                {data.allWordpressWpFood.nodes.map(item => {
                    const LearnMoreBtn = slug => (
                        <Link to={`/food/${slug}`} className="cta-btn">
                            <span>Learn More</span>
                        </Link>
                    )

                    const PlaceHolder = () => (
                        <div style={{ height: 48 + "px" }}></div>
                    )
                    return (
                        <div className="menu-item" key={item.id}>
                            <div className="menu-item__meta">
                                <h4 className="menu-item__title">
                                    {item.title}
                                </h4>
                                <p className="menu-item__description">
                                    {item.acf.summary}
                                </p>
                                {item.acf.showReadMore &&
                                    LearnMoreBtn(item.slug)}
                                {!item.acf.showReadMore && PlaceHolder()}
                            </div>
                            <div className="menu-item__overlay" />
                            {item.featured_media && (
                                <Img
                                    fixed={
                                        item.featured_media.localFile
                                            .childImageSharp.fixed
                                    }
                                    alt={item.featured_media.title}
                                    style={{
                                        position: "absolute",
                                        left: 0,
                                        top: 0,
                                        width: "100%",
                                        height: "100%",
                                    }}
                                />
                            )}
                        </div>
                    )
                })}
            </div>
        )}
    />
)

export default FoodItems
